import {
  calculateDeposit,
  // calculateVat,
} from '../components/Checkout/calculations';

const StoreData = {
  getProductByID: id => {
    return productRequest(id);
  },
  getProductMultipleByID: (id, page) => {
    return productRequest('', `?include=${id}&per_page=${page}`);
  },
  checkCoupon: id => {
    return couponRequest(id);
  },
  getOrders: (id, page, uid) => {
    return orderRequest(`?include=${id}&per_page=${page}`, uid);
  },
  createOrder: data => {
    return orderCreate(data);
  },
  updateOrder: (id, data) => {
    return orderUpdate(id, data);
  },
  paymentIntent: res => {
    return createPaymentIntent(res);
  },
  getReviews: id => {
    return getReviewData(id);
  },
  submitReview: data => {
    return createReview(data);
  },
  // validateCompany: number => {
  //   return companyValidate(number);
  // },
  getQuoteOptions: data => {
    return quoteOptions(data);
  },
  getQuoteCalculation: data => {
    return quoteCalculation(data);
  },
};

export default StoreData;

const shopID = 'TESTSHOP';

const standardParams = {
  method: 'POST',
  headers: {
    'Content-Type': 'application/json',
  },
};

async function productRequest(id, query = '') {
  const url = `${process.env.GATSBY_FUNCTIONS_URL}/shop/products`;
  const params = {
    ...standardParams,
    body: JSON.stringify({
      endpoint: id,
      query,
    }),
  };
  return (await fetch(url, params)).json();
}

async function couponRequest(id) {
  const url = `${process.env.GATSBY_FUNCTIONS_URL}/shop/coupons`;
  const params = {
    ...standardParams,
    body: JSON.stringify({ code: id }),
  };
  return (await fetch(url, params)).json();
}

async function orderRequest(query, uid) {
  const url = `${process.env.GATSBY_FUNCTIONS_URL}/shop/orders`;
  const params = {
    ...standardParams,
    body: JSON.stringify({
      query,
      uid,
    }),
  };
  return (await fetch(url, params)).json();
}

async function orderCreate(data) {
  const url = `${process.env.GATSBY_FUNCTIONS_URL}/shop/orders/create`;

  const address = {
    first_name: data.first_name,
    last_name: data.last_name,
    address_1: data.street_address,
    address_2: '',
    city: data.city,
    state: data.county,
    postcode: data.post_code,
    country: 'GB',
  };

  // const shipping = [
  //   {
  //     method_id: data.shipping.method_id,
  //     method_title: data.shipping.method_title,
  //     total: data.shipping.total,
  //   },
  // ];

  const discount = !data.discount ? [] : [{ code: data.discount.code }];

  const productData = data.products.filter(
    x => x.id !== 'shipping' && x.id !== 'discount',
  );

  const products = [];

  productData.forEach(product => {
    const { databaseId, quantity, price, id, ...options } = product;

    /* Get deposit total */
    const depositTotal = calculateDeposit(price).amount;

    /* Get Subtotal */
    const activeTotal = data.deposit ? depositTotal : price;
    const subTotal = parseFloat(Math.round(activeTotal * 100) / 100);

    products.push({
      product_id: databaseId,
      quantity,
      total: subTotal.toString(),
      tax_class: data.meta !== null ? '' : 'zero-rate',
      meta_data: [
        {
          key: 'options',
          value: options,
        },
      ],
    });
  });

  const onDelivery = data.totals?.filter(x => x.label === 'On delivery')[0];

  const orderData = {
    payment_method: data.payment === 0 ? 'stripe' : 'invoice',
    payment_method_title: data.payment === 0 ? 'Credit/Debit Card' : 'Invoice',
    set_paid: false,
    billing: {
      ...address,
      email: data.email_address,
      phone: data.phone_number,
    },
    shipping: {
      ...address,
    },
    line_items: products,
    // shipping_lines: shipping,
    coupon_lines: discount,
    meta_data: [
      {
        key: 'user_account',
        value: data.meta !== null ? data.meta.uid : null,
      },
      {
        key: 'user_data',
        value: data.user,
      },
      {
        key: 'deposit',
        value: data.deposit && onDelivery.amount,
      },
    ],
  };

  const params = {
    ...standardParams,
    body: JSON.stringify({ data: orderData }),
  };
  return (await fetch(url, params)).json();
}

async function orderUpdate(id, data) {
  const url = `${process.env.GATSBY_FUNCTIONS_URL}/shop/orders/update`;
  const orderData = {
    set_paid: true,
    transaction_id: data,
  };
  const params = {
    ...standardParams,
    body: JSON.stringify({
      id,
      data: orderData,
    }),
  };
  return (await fetch(url, params)).json();
}

async function createPaymentIntent(res) {
  const url = `${process.env.GATSBY_FUNCTIONS_URL}/payment/intent`;
  const amount = Math.round(res.total * 100);
  const id = `${shopID}#${res.id}`;
  const params = {
    ...standardParams,
    body: JSON.stringify({ amount, id }),
  };
  return (await fetch(url, params)).json();
}

async function getReviewData(id) {
  const url = `${process.env.GATSBY_FUNCTIONS_URL}/shop/reviews`;
  const params = {
    ...standardParams,
    body: JSON.stringify({ id }),
  };
  return (await fetch(url, params)).json();
}

async function createReview(data) {
  const url = `${process.env.GATSBY_FUNCTIONS_URL}/shop/reviews/create`;
  const params = {
    ...standardParams,
    body: JSON.stringify({ data }),
  };
  return (await fetch(url, params)).json();
}

// async function companyValidate(number) {
//   const url = `${process.env.GATSBY_FUNCTIONS_URL}/shop/register/validate`;
//   const params = {
//     ...standardParams,
//     body: JSON.stringify({ number }),
//   };
//   return (await fetch(url, params)).json();
// }

async function quoteOptions(data) {
  const url = `${process.env.GATSBY_FUNCTIONS_URL}/shop/quote/options`;
  const params = {
    ...standardParams,
    body: JSON.stringify({ data }),
  };
  return (await fetch(url, params)).json();
}

async function quoteCalculation(data) {
  const url = `${process.env.GATSBY_FUNCTIONS_URL}/shop/quote/calculate`;
  const params = {
    ...standardParams,
    body: JSON.stringify({ data }),
  };
  return (await fetch(url, params)).json();
}
