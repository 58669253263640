export const vatAmount = 20;
export const depositAmount = 50;

export function calculateVat(val) {
  return Math.round((val / 100) * vatAmount * 100) / 100;
}

export function calculateDeposit(val) {
  /* Get deposit amount */
  const amount = (val / 100) * depositAmount;
  /* Get remaining amount after deposit subtracted */
  const remaining = Math.round((val - amount) * 100) / 100;
  /* Get deposit total */
  const depositTotal = parseFloat(Math.round(amount * 100) / 100);

  return { amount, remaining, depositTotal };
}
