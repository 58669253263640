import React from 'react';

const Arrow = () => {
  return (
    <div style={iconStyles}>
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
        <path
          d="M11.52 19.5C10.8848 19.5008 10.28 19.2166 9.86016 18.72L0.441598 7.568C-0.177534 6.79941 -0.0943976 5.65653 0.628846 4.9939C1.35209 4.33126 2.45089 4.39125 3.10368 5.129L11.3395 14.881C11.3851 14.9352 11.4509 14.9662 11.52 14.9662C11.5891 14.9662 11.6549 14.9352 11.7005 14.881L19.9363 5.129C20.3498 4.61567 20.9978 4.3759 21.6296 4.50249C22.2613 4.62908 22.7776 5.10216 22.9788 5.73867C23.18 6.37518 23.0344 7.07526 22.5984 7.568L13.1827 18.716C12.7622 19.2138 12.1566 19.4994 11.52 19.5Z"
          fill="#222"
        />
      </svg>
    </div>
  );
};

export default { Arrow };

const iconStyles = {
  display: `inline-block`,
  width: `8px`,
  height: `8px`,
  lineHeight: 0,
  marginLeft: `15px`,
};
