import React from 'react';

import StoreData from '../../data/store';

import './orders.css';
import { BorderRadius, MainColour, TextColour } from '../../utils/variables';

import Icon from './icons';
import Spinner from '../Placeholder/spinner';

import loadComponent from '../Loadable';
import { calculateVat } from '../Checkout/calculations';
// import loadLibrary from '../Loadable/library';

// const Img = loadLibrary('gatsby-image');

const Title = loadComponent('h6');
// const Link = loadComponent('link');
const Alert = loadComponent('alert');
// const Placeholder = loadComponent('product-placeholder');
const Button = loadComponent('button-hidden');

export default class Orders extends React.Component {
  _initFirebase = false;

  _firebaseOrders = null;

  constructor(props) {
    super(props);
    this.state = {
      status: true,
      orders: [],
      orderOpen: null,
    };
    this.checkStatus = this.checkStatus.bind(this);
    this.openOrder = this.openOrder.bind(this);
  }

  componentDidMount() {
    this.firebaseInit();
  }

  componentDidUpdate() {
    this.firebaseInit();
  }

  componentWillUnmount() {
    this._initFirebase = false;
    const { firebase } = this.props;
    if (firebase && this._firebaseOrders !== null) {
      this._firebaseOrders();
    }
  }

  firebaseInit = () => {
    const { firebase, authUser } = this.props;
    if (firebase && !this._initFirebase && authUser) {
      this._initFirebase = true;
      const { readDatabase, orders } = firebase;

      readDatabase(orders(authUser.uid), snapshot => {
        const ordersObject = snapshot.val();
        if (ordersObject !== null) {
          let orders = Object.keys(ordersObject).map(key => ({
            ...ordersObject[key],
          }));
          orders = orders.sort((a, b) => {
            const dateA = new Date(a.date_created);
            const dateB = new Date(b.date_created);
            return dateB - dateA;
          });
          this.setState({ orders }, () => {
            this.checkStatus();
          });
        }
      });
    }
  };

  getStatus = (order, status) => {
    const loadingLabel = `Loading...`;
    if (status) {
      return <Spinner label={loadingLabel} />;
    }

    if (order.status) {
      return (
        <span style={{ color: `#155724` }}>
          {order.status.charAt(0).toUpperCase() + order.status.slice(1)}
        </span>
      );
    }

    return <span style={{ color: `#FF0000` }}>Contact Us</span>;
  };

  checkStatus() {
    const { authUser } = this.props;
    const { orders } = this.state;
    const order_ids = [];
    const order_status = [];
    const order_data = [];
    orders.forEach(order => {
      order_ids.push(order.id);
    });
    StoreData.getOrders(order_ids.join(), order_ids.length, authUser.uid)
      .then(res => {
        res.forEach(status => {
          order_status.push({
            id: status.id,
            status: status.status,
          });
        });
        return order_status;
      })
      .then(res => {
        orders.map(item => {
          const obj = res.find(x => x.id === item.id);
          return order_data.push({ ...item, ...obj });
        });
        return order_data;
      })
      .then(res => {
        if (this._initFirebase) {
          this.setState({
            orders: res,
            status: false,
          });
        }
      });
  }

  openOrder(id) {
    if (this._initFirebase) this.setState({ orderOpen: id });
  }

  render() {
    const { orders, status, orderOpen } = this.state;

    const itemName = val => {
      return val.charAt(0).toUpperCase() + val.slice(1);
    };

    return (
      <div className="accountOrders">
        {orders.length > 0 ? (
          orders.map((order, i) => (
            <div
              key={order.id}
              style={{
                ...singleStyle,
                marginTop: i !== 0 && `1.875rem`,
              }}
            >
              <div className="header" style={headerStyle}>
                <div className="details">
                  <strong>Reference</strong> #{order.id}
                </div>
                <div className="date">
                  <strong>Created</strong>
                  {new Date(order.date_created).toLocaleString('en-GB')}
                </div>
                <div className="shipping">
                  <strong>Address</strong>{' '}
                  <span>
                    {order.shipping.address_1}, {order.shipping.postcode}
                  </span>
                </div>
                <div className="status">
                  <strong>Status</strong>
                  {this.getStatus(order, status)}
                </div>
                <div className="total">
                  <strong>
                    {order.status === 'pending' ? 'To Pay' : 'Paid'}
                  </strong>{' '}
                  &pound;{order.total} <small>(inc VAT)</small>
                </div>
              </div>
              {orderOpen !== order.id && (
                <Button
                  onClick={() => this.openOrder(order.id)}
                  style={buttonStyle}
                >
                  View Order Details <Icon.Arrow />
                </Button>
              )}
              <div
                className="items"
                style={{
                  ...itemsList,
                  height: orderOpen === order.id ? `auto` : 0,
                  borderTopColor:
                    orderOpen === order.id
                      ? `rgba(0,0,0,0.2)`
                      : `rgba(0,0,0,0)`,
                  opacity: orderOpen === order.id ? 1 : 0,
                }}
              >
                <ul>
                  {order.items.map((item, i) => {
                    const itemPrice =
                      Math.round(item.price * item.quantity * 100) / 100;
                    const itemVat = calculateVat(itemPrice);
                    return (
                      <li
                        key={item.databaseId}
                        style={{
                          ...itemsLi,
                          paddingTop: i !== 0 && `30px`,
                          marginTop: i !== 0 && `30px`,
                          borderTop: i !== 0 && `1px dotted rgba(0,0,0,0.2)`,
                        }}
                      >
                        <div className="text" style={itemText}>
                          <Title
                            size="26px"
                            line={1}
                            margin="0.9375rem"
                            style={{ textTransform: `uppercase` }}
                          >
                            {itemName(item.product)}
                          </Title>
                          <small
                            style={{
                              display: `flex`,
                              flexDirection: `column`,
                              marginBottom: `15px`,
                            }}
                          >
                            {item.reference && (
                              <span>Reference: {item.reference}</span>
                            )}
                            <span>
                              Dimensions (WxH): {item.width}x{item.height}mm
                            </span>
                          </small>
                          <ul className="amount" style={itemAmount}>
                            {/* <li style={itemAmountLi}>
                              <strong>Price:</strong> &pound;{item.price}
                            </li> */}
                            <li style={itemAmountLi}>
                              <strong>Quantity:</strong> {item.quantity}
                            </li>
                          </ul>
                        </div>
                        <div className="subtotal" style={subTotal}>
                          <strong style={subTotalStrong}>Price (Ex VAT)</strong>
                          <span style={subTotalSpan}>
                            &pound;
                            {parseFloat(itemPrice).toFixed(2)}
                          </span>
                          <strong
                            style={{ ...subTotalStrong, marginTop: `10px` }}
                          >
                            Price (Inc VAT)
                          </strong>
                          <span style={subTotalSpan}>
                            &pound;
                            {parseFloat(itemPrice + itemVat).toFixed(2)}
                          </span>
                        </div>
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>
          ))
        ) : (
          <Alert error>No orders found</Alert>
        )}
      </div>
    );
  }
}

const singleStyle = {
  border: `1px solid rgba(0,0,0,0.2)`,
  borderRadius: BorderRadius,
  overflow: `hidden`,
  position: `relative`,
};

const headerStyle = {
  backgroundColor: `#f7f7f7`,
  padding: `24px 30px`,
  display: `flex`,
  alignItems: `center`,
  justifyContent: `space-between`,
  fontSize: `0.875rem`,
};

const itemsList = {
  borderTopWidth: `1px`,
  borderTopStyle: `solid`,
  padding: `1.875rem`,
  overflow: 'hidden',
};

const itemsLi = {
  display: `flex`,
  alignItems: `center`,
};

const itemText = {
  padding: `0 1.875rem 0 0`,
};

const itemAmount = {
  color: MainColour,
};

const itemAmountLi = {
  display: `inline-block`,
  paddingTop: 0,
  marginTop: 0,
  borderTop: 0,
};

const subTotal = {
  marginLeft: `auto`,
  textAlign: `right`,
};

const subTotalStrong = {
  display: `block`,
  fontSize: `0.875rem`,
  textTransform: `uppercase`,
  lineHeight: 1,
};

const subTotalSpan = {
  display: `block`,
  color: MainColour,
};

const buttonStyle = {
  display: `flex`,
  alignItems: `center`,
  fontSize: `0.875rem`,
  color: TextColour,
  position: `absolute`,
  zIndex: 20,
  left: `50%`,
  bottom: `22px`,
  transform: `translate(-50%,0)`,
};
