import React from 'react';

import Loader from '../../images/icon-loader-dark.svg';

export default function Spinner({ size, label, text, style }) {
  const loaderSize = size || `16px`;
  return (
    <img
      src={Loader}
      height={loaderSize}
      width={loaderSize}
      style={{ marginLeft: text && `0.625rem`, ...style }}
      alt={label}
    />
  );
}
